document.addEventListener('turbolinks:load', () => {
  let widget = document.getElementById('priv-widget')
  if (widget) { widget.parentNode.removeChild(widget); }

  // BEGIN PRIVY ASYNCHRONOUS WIDGET CODE

    let code = "var _d_site = _d_site || '81A2E03448E649A896B40BF8'; (function(p, r, i, v, y) { p[i] = p[i] || function() { (p[i].q = p[i].q || []).push(arguments) }; v = r.createElement('script'); v.async = 1; v.src = 'https://widget.privy.com/assets/widget.js'; y = r.getElementsByTagName('script')[0]; y.parentNode.insertBefore(v, y); })(window, document, 'Privy');"

  // END PRIVY ASYNCHRONOUS WIDGET CODE
  let script = document.createElement('script');
  script.id = 'priv-widget';
  script.type = 'text/javascript';
  script.async = true;
  script.innerHTML = code;
  script.setAttribute('data-turbolinks-eval', 'false');
  document.head.appendChild(script);
});